import React, { useState } from "react";

import { Container, Icon, ProductCard, ProductCardList } from "../../../";

import { OfferCard } from "../../../quotes-panel/components";

import css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/lottie.json";
import { getJSDocParameterTags } from "typescript";
import { connect } from "react-redux";
import { getIconPropsForPlan } from "../../../../utils"

let sjson;
if (typeof window !== "undefined") {
  if (window.matchMedia("(max-width: 780px)").matches) {
     sjson = {
      AL: "AL",
      AK: "AK",
      AS: "AS",
      AZ: "AZ",
      AR: "AR",
      CA: "CA",
      CO: "CO",
      CT: "CT",
      DE: "DE",
      DC: "DC",
      FM: "FM",
      FL: "FL",
      GA: "GA",
      GU: "GU",
      HI: "HI",
      ID: "ID",
      IL: "IL",
      IN: "IN",
      IA: "IA",
      KS: "KS",
      KY: "KY",
      LA: "LA",
      ME: "ME",
      MH: "MH",
      MD: "MD",
      MA: "MA",
      MI: "MI",
      MN: "MN",
      MS: "MS",
      MO: "MO",
      MT: "MT",
      NE: "NE",
      NV: "NV",
      NH: "NH",
      NJ: "NJ",
      NM: "NM",
      NY: "NY",
      NC: "NC",
      ND: "ND",
      MP: "MP",
      OH: "OH",
      OK: "OK",
      OR: "OR",
      PW: "PW",
      PA: "PA",
      PR: "PR",
      RI: "RI",
      SC: "SC",
      SD: "SD",
      TN: "TN",
      TX: "TX",
      UT: "UT",
      VT: "VT",
      VI: "VI",
      VA: "VA",
      WA: "WA",
      WV: "WV",
      WI: "WI",
      WY: "WY",
    };
    
  } else {
     sjson = {
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FM: "Federated States Of Micronesia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    };
  }
}

const EnrollmentSummary = (props) => {
  const params = JSON.parse(localStorage.getItem("params"));

  const enrollmentFee =
    props.enrollment.nationalGeneral.fees.filter(
      (f) => f.label == "One time fees"
    ).length == 1
      ? props.enrollment.nationalGeneral.fees.filter(
        (f) => f.label == "One time fees"
      )[0].value
      : 0;

  const montlyContribution = JSON.parse(localStorage.getItem("plans")).reduce((a, b) => {
    return a + Number(b.plan_only_premium.value);
  }, 0);

  return (
    <Container className={css.summaryContainer}>
      <Container className={``}>
        <Container className={css.selectedProductsAndPersonalInfo}>
          <Container className={css.personalInfoContainer}>
            <Container>
              <Container className={css.checkoutTitle}>
                Review and Complete your Application:
              </Container>
              <Container className={css.iconAndSummary}>
                <Container className={css.icon}>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 240, height: 240 }}
                  />
                </Container>
              </Container>
            </Container>
            <Container className={css.personalInfoCard}>
              <Container className={css.personalInfoTitle}>
                Personal Info:
              </Container>
              <Container className={css.personalInfoValues}>
                <Container className={css.personalInfoValue}>
                  <span>Name: </span>
                  {params.firstName || "John"} {params.lastName || "Diego"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Age: </span>
                  {params.age || "23"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Gender: </span>
                  {params.gender || "Male"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Tobacco: </span>
                  {params.usesTobacco ? "Yes" : "No"}
                </Container>

                <Container className={css.personalInfoValue}>
                  <span>State: </span>
                  {sjson[params.state]}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Phone: </span>
                  {params.phone || "(212) 1234567"}
                </Container>

                <Container
                  className={`${css.personalInfoValue} ${css.emailValue}`}
                >
                  <span>Email: </span>
                  {params.email}
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className={css.selectedProductsContainer}>
            <Container>
              <Container className={css.selectedProductsTitle}>
                Selected Products:
              </Container>
            </Container>
            <Container className={css.productCards}>
              {JSON.parse(localStorage.getItem("plans")).map((p) => {
                return (
                  <Container
                    key={"kky" + p.plan_name.value}
                    className={css.planCard}
                  >
                    <Container className={css.planCardIcon}>
                      <Icon
                        style={{ width: "48px", height: "48px" }}
                        {...getIconPropsForPlan(p)}
                      />
                    </Container>
                    <Container className={css.planCardInfo}>
                      <Container className={css.planCardCarrierName}>
                        {p.carrier.value}
                      </Container>
                      <Container className={css.planCardName}>
                        {p.plan_name.value}
                      </Container>
                    </Container>
                    <Container className={css.planCardPremium}>
                      ${Number(p.plan_only_premium.value).toFixed(2)}
                    </Container>
                  </Container>
                );
              })}
              <Container className={css.payments}>
                <Container className={css.applicants}>
                  <Container className={css.applicantLabel}>
                    Applicants:{" "}
                  </Container>
                  <Container className={css.applicantsDropDowns}>
                    <Container className={css.adultContainer}>
                      {props.applicants.spouse ? "2 Adults" : "1 Adult"}
                    </Container>
                    <Container className={css.dependentContainer}>
                      {props.applicants.dependents.length} Dependents
                    </Container>
                  </Container>
                </Container>
                <Container className={css.tax}>
                  <Container className={css.taxName}>Monthly contribution</Container>
                  <Container className={css.taxValue}>${(montlyContribution).toFixed(2)} </Container>
                </Container>
              <Container className={css.taxes}>
                  {props.enrollment.nationalGeneral.fees.reverse().map((f) => {
                    return (
                      <Container
                        key={"k-" + f.label + f.value}
                        className={css.tax}
                      >
                        <Container className={css.taxName}>{f.label}</Container>
                        <Container className={css.taxValue}>
                          ${f.value.toFixed(2)}
                        </Container>
                      </Container>
                    );
                  })}
                </Container>
                <Container className={css.total}>
                  <Container style={{ fontWeight: 700 }} className={css.totalLabel}>Today's Payment</Container>
                  <Container>${(enrollmentFee + montlyContribution + props.enrollment.nationalGeneral.feesRaw['MONTHLY'].total).toFixed(2)} </Container>
                </Container>
              <Container className={ css.monthlyTotal}>
                <Container className={ css.monthlyTotalLabel }>Monthly Payment</Container>
                  <Container>${(props.enrollment.nationalGeneral.feesRaw['MONTHLY'].total + montlyContribution).toFixed(2)}</Container>
              </Container>
               
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
  };
};

export default connect(mapStateToProps)(EnrollmentSummary);
